import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|min:2|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VTextField,{ref:"title",attrs:{"disabled":_vm.loading,"label":"Group Name","error-messages":errors},model:{value:(_vm.groupLocal.title),callback:function ($$v) {_vm.$set(_vm.groupLocal, "title", $$v)},expression:"groupLocal.title"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.status,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","error-messages":errors,"label":"Status"},model:{value:(_vm.groupLocal.group_statuses_id),callback:function ($$v) {_vm.$set(_vm.groupLocal, "group_statuses_id", $$v)},expression:"groupLocal.group_statuses_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Start Date","rules":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"Start Date","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.groupLocal.start_date),callback:function ($$v) {_vm.$set(_vm.groupLocal, "start_date", $$v)},expression:"groupLocal.start_date"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"End Date","rules":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"End Date","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.groupLocal.end_date),callback:function ($$v) {_vm.$set(_vm.groupLocal, "end_date", $$v)},expression:"groupLocal.end_date"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Paperwork"}},[_c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.paperworkgroups,"menu-props":{ maxHeight: '400' },"item-text":"title","item-value":"id","label":"Paperwork"},model:{value:(_vm.groupLocal.paperwork_groups_id),callback:function ($$v) {_vm.$set(_vm.groupLocal, "paperwork_groups_id", $$v)},expression:"groupLocal.paperwork_groups_id"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Client Reference Number","rules":"required"}},[_c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Client Reference Number"},model:{value:(_vm.groupLocal.client_reference),callback:function ($$v) {_vm.$set(_vm.groupLocal, "client_reference", $$v)},expression:"groupLocal.client_reference"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('AutoComplete',{attrs:{"disabled":_vm.loading,"label":"Client","error-messages":errors,"search-service":"organisation"},model:{value:(_vm.groupLocal.client),callback:function ($$v) {_vm.$set(_vm.groupLocal, "client", $$v)},expression:"groupLocal.client"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Client Manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('AutoComplete',{attrs:{"disabled":_vm.loading,"label":"Client Manager","error-messages":errors,"search-service":"person","sort-by":"surname"},model:{value:(_vm.groupLocal.client_manager),callback:function ($$v) {_vm.$set(_vm.groupLocal, "client_manager", $$v)},expression:"groupLocal.client_manager"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.types,"item-text":"title","item-value":"id","menu-props":{ maxHeight: '400' },"label":"Group Type","error-messages":errors},model:{value:(_vm.groupLocal.group_types_id),callback:function ($$v) {_vm.$set(_vm.groupLocal, "group_types_id", $$v)},expression:"groupLocal.group_types_id"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Branch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('AutoComplete',{attrs:{"disabled":_vm.loading,"label":"Branch","error-messages":errors,"search-service":"organisation"},model:{value:(_vm.groupLocal.branch),callback:function ($$v) {_vm.$set(_vm.groupLocal, "branch", $$v)},expression:"groupLocal.branch"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Proposed Teacher Escort"}},[_c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Proposed Teacher Escort"},model:{value:(_vm.groupLocal.proposed_teachers),callback:function ($$v) {_vm.$set(_vm.groupLocal, "proposed_teachers", $$v)},expression:"groupLocal.proposed_teachers"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('ValidationProvider',{attrs:{"name":"Proposed Students"}},[_c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Proposed Students"},model:{value:(_vm.groupLocal.proposed_students),callback:function ($$v) {_vm.$set(_vm.groupLocal, "proposed_students", $$v)},expression:"groupLocal.proposed_students"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Single Placement Guaranteed"}},[_c(VSwitch,{attrs:{"label":"Single Placement Guaranteed"},model:{value:(_vm.groupLocal.single_placement_guranteed),callback:function ($$v) {_vm.$set(_vm.groupLocal, "single_placement_guranteed", $$v)},expression:"groupLocal.single_placement_guranteed"}})],1)],1),(_vm.groupLocal.group_statuses_id == 2)?_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Cancellation Date","rules":"required|date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"Cancellation Date","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.groupLocal.cancellation_date),callback:function ($$v) {_vm.$set(_vm.groupLocal, "cancellation_date", $$v)},expression:"groupLocal.cancellation_date"}})}}],null,true)})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Notes"}},[_c(VTextarea,{attrs:{"label":"Notes","rows":"3","filled":"","auto-grow":""},model:{value:(_vm.groupLocal.notes),callback:function ($$v) {_vm.$set(_vm.groupLocal, "notes", $$v)},expression:"groupLocal.notes"}})],1)],1)],1),_c(VBtn,{staticClass:"primary",attrs:{"disabled":_vm.loading || invalid || !dirty},on:{"click":_vm.saveAction}},[_vm._v("Save")])],1)]}}])}),_c('ConfirmDialog',{attrs:{"show":_vm.showNavigateAwayConfirmDialog},on:{"confirm-dialog-clicked":_vm.confirmNavigate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }