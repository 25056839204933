<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
      <v-form>
        <v-row>
          <v-col cols="12" md="8">
            <ValidationProvider name="Name" rules="required|min:2|max:255">
              <v-text-field
                v-model="groupLocal.title"
                :disabled="loading"
                label="Group Name"
                :error-messages="errors"
                slot-scope="{ errors }"
                ref="title"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Status" rules="required">
              <v-select
                v-model="groupLocal.group_statuses_id"
                :disabled="loading"
                :items="lists.status"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Status"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Start Date" rules="date">
              <DateInput
                v-model="groupLocal.start_date"
                label="Start Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="End Date" rules="date">
              <DateInput
                v-model="groupLocal.end_date"
                label="End Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Paperwork">
              <v-select
                v-model="groupLocal.paperwork_groups_id"
                :disabled="loading"
                :items="lists.paperworkgroups"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                label="Paperwork"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Client Reference Number" rules="required">
              <v-text-field
                v-model="groupLocal.client_reference"
                :disabled="loading"
                label="Client Reference Number"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Client" rules="required">
              <AutoComplete
                v-model="groupLocal.client"
                :disabled="loading"
                label="Client"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="organisation"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Client Manager">
              <AutoComplete
                v-model="groupLocal.client_manager"
                :disabled="loading"
                label="Client Manager"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="person"
                sort-by="surname"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Type" rules="required">
              <v-select
                v-model="groupLocal.group_types_id"
                :disabled="loading"
                :items="lists.types"
                item-text="title"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Group Type"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Branch">
              <AutoComplete
                v-model="groupLocal.branch"
                :disabled="loading"
                label="Branch"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="organisation"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Proposed Teacher Escort">
              <v-text-field
                v-model="groupLocal.proposed_teachers"
                :disabled="loading"
                label="Proposed Teacher Escort"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <ValidationProvider name="Proposed Students">
              <v-text-field
                v-model="groupLocal.proposed_students"
                :disabled="loading"
                label="Proposed Students"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Single Placement Guaranteed">
              <v-switch v-model="groupLocal.single_placement_guranteed" label="Single Placement Guaranteed"></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4" v-if="groupLocal.group_statuses_id == 2">
            <ValidationProvider name="Cancellation Date" rules="required|date">
              <DateInput
                v-model="groupLocal.cancellation_date"
                label="Cancellation Date"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Notes">
              <v-textarea v-model="groupLocal.notes" label="Notes" rows="3" filled auto-grow></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
      </v-form>
    </ValidationObserver>
    <ConfirmDialog :show="showNavigateAwayConfirmDialog" @confirm-dialog-clicked="confirmNavigate" />
  </v-container>
</template>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import { date } from '@/utils/helpers';
import DateInput from '@/views/DateInput';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import AutoComplete from '@/components/AutoComplete';

extend('required', required);
extend('max', max);
extend('min', min);
extend('date', date);

export default {
  name: 'GroupDetailForm',
  beforeRouteLeave(to, from, next) {
    if (this.isDirty()) {
      this.next = next;
      this.showNavigateAwayConfirmDialog = true;
    } else {
      next();
    }
  },
  components: {
    AutoComplete,
    ConfirmDialog,
    DateInput,
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('group', ['loading', 'group', 'error', 'success', 'meta']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        status: [],
        types: [],
        paperworkgroups: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save. This is where we
     * set the default values for new records
     */
    groupLocal: function () {
      const localGroup = loadDash.cloneDeep(this.group);
      if (this.isNewRecord()) {
        localGroup.group_statuses_id = 1;
        localGroup.paperwork_groups_id = 1;
        localGroup.group_types_id = 1;
      }
      this.focusFirstField();
      return localGroup;
    }
  },
  methods: {
    /**
     * Set the focus on the first field if possible
     */
    focusFirstField() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.title.focus();
        }, 100);
      });
    },
    /**
     * Check if this a new record
     */
    isNewRecord() {
      return this.$route.params.id == 'new' ? true : false;
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        await store.dispatch('group/save', {
          id: this.groupLocal.id,
          group: this.groupLocal
        });
      }
      this.resetValidation();
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    },
    /**
     * Check if the form has changed after loading
     */
    isDirty() {
      return this.$refs.objects._data.flags.dirty;
    },
    confirmNavigate: function (navigateAway) {
      this.showNavigateAwayConfirmDialog = false;
      if (navigateAway) {
        this.next();
      }
    }
  },
  data: () => ({
    showNavigateAwayConfirmDialog: false
  })
};
</script>
